@import url("./styles/font.css");

html {
  font-size: 62.5%;
  background-color: #000;
}

* {
  box-sizing: border-box;
  color: #ffffff;
  // overflow-x: hidden;
  scroll-behavior: smooth;
}

/****** Constants *******/
.pointer {
  cursor: pointer;
}

.pointer-title {
  cursor: pointer;
  animation: fontChange 2s infinite forwards;

  &:hover {
    animation-play-state: paused;
  }
}

/****** Constants *******/

header {
  height: fit-content;
  // display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  // grid-template-columns: 1fr 1fr 1fr;
  // background-color: yellow;
  max-width: 1600px;
  width: 100%;
  margin: auto;
  padding: 0 20px;
  // display: none;

  .hamburger {
    display: none;
  }

  .header_title {
    font-size: 7rem;
    text-transform: uppercase;
    /* margin: auto; */
    width: 100%;
    //  background-color: wheat;
    // flex: 2;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    & > img {
      width: 200px;
      height: auto;
      // background: yellow;
    }
  }

  .helicopter {
    font-size: 3rem;
    /* background-color: yellow; */
    margin-bottom: -2rem;
    transform: translateY(-100px);
  }

 
  .home {
    overflow-x: hidden;
    position: relative;
  }

  .nav {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 1.8rem;
    font-weight: 700;
    // flex: 1;
    overflow-x: unset;
    // background-color: yellow;
    width: fit-content;
    font-family: RocGroteskWide;
    text-transform: uppercase;

    a {
      // margin-left: 20px;
      cursor: pointer;
      overflow-x: unset;
      text-decoration: none;
      // background: blue;

      // &:hover {
      //   font-family: Plact;
      // }
    }
  }
}

main {
  /* height: 75vh; */
  // display: none;
  font-family: TimesCG, sans-serif;

  .amanda-svg {
    position: absolute;
    width: fit-content;
    top: 0;
    left: 0;
    // transform: translateX(-50%) scale(1.3);
    stroke-width: 1px;
    // stroke-dasharray: 2500;
    // stroke-dashoffset: 2500;
    // animation: drawamanda 4s linear infinite;
    stroke: #ccc;
    z-index: -1;

    @media (max-width: 565px) {
      display: none;
    }
  }
}

.App {
  overflow-x: hidden;
}

.hero-main {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: yellow;
  overflow: hidden;

  & > img {
    width: 300px;
    height: auto;
    // background: yellow;
    // padding: 20px;
  }
}

.hero {
  width: 1200px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: auto;
  margin-top: 100px;
  padding: 0 20px;

  @media (max-width: 769px) {
    margin-top: 0;
  }

  // margin-top: 50px;
  // margin-bottom: 10rem;
  // justify-content: space-between;
  // position: relative;
  // background: blue;

  .bio {
    width: 100%;
    max-width: 120ch;
    // background-color: yellow;
    color: #f0f0f0;
    // background: yellow;
    font-size: 1.6rem;
    line-height: 150%;
    // font-weight: 700;
    font-family: TimesCG;
    text-align: center;


    @media (max-width: 565px) {
      font-size: 10px;
    }
  }

 

 

  .bio_link {
    color: #ffffff;
    cursor: pointer;
    font-weight: 300;

    a {
      text-decoration: none;
      color: #c3fee0;

      &:hover {
        text-decoration: underline;
        // font-family: Plact;
        // font-size: 1.9rem;
      }
    }
  }

  & > button {
    border: none;
    outline: none;
    background: transparent;
    font-size: 1.6rem;
    border: 1px solid #ffffff;
    padding: 2px 10px;
    cursor: pointer;
    margin-top: 1rem;
    transform: translateY(-20px);
    font-family: RocGroteskWide;
    margin-top: 40px;
    // transition: 0.2s;
  
    
  }
}

.youtube_sec {
  margin-top: 0;
  margin-bottom: 0;

  svg {
    width: 40px;
    cursor: pointer;
  }
}

.music {
  width: 1200px;
  max-width: 100%;
  padding: 0 20px;
  // background-color: yellow;
  margin: auto;
  height: 100%;
  /* background-color: #f0f0f0; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;

  @media (max-width: 1024px) {
    padding: 0;
    margin-top: 0;
  }

  .music_slider {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 5rem;
    }
  }

  .music_title {
    font-size: 4rem;
    text-align: center;
    width: fit-content;
    margin: auto;
    margin-bottom: 4rem;
    margin-top: 0;
  }

  .music_inner {
    display: flex;
    gap: 40px;
    align-items: center;
    // justify-content: space-between;
    overflow-x: auto;
    scroll-behavior: smooth;
    /* flex: 2; */
    /* background-color: yellow; */
    width: 85%;
    cursor: pointer;

 

    &::-webkit-scrollbar {
      display: none;
    }

    & > a {
      position: relative;
      text-decoration: none;
      font-family: Afronaut;

      img {
        width: 30rem;
        height: auto;
        cursor: pointer;

        @media (max-width: 1024px) {
          min-width: 100%;
          margin-left: 10px;
        }
      }

      .shows_image {
        width: 30rem !important;
        height: 294px;
        object-fit: cover;
        cursor: pointer;

        @media (max-width: 1024px) {
          min-width: 100% !important;
          margin-left: 10px;
        }
      }

      .music_artwork{
        transition: 0.4s;
      }

      

      .music_gif {
        height: 305px;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.5s;
        overflow-y: hidden;
        transition: 0.4s;

        img {
          margin-top: -160px;
        }
      }

      .store_gif {
        img {
          margin-top: 0 !important;
        }
      }
    }

    & > a:hover {
      .music_gif {
        opacity: 1;
      }

      .music_artwork{
        opacity: 0;
      }

      // .music_name {
      //   font-family: Plact;
      // }

      .music_button {
        transform: translateY(0);
        opacity: 1;
        transition: 0.4s;
        font-family: RocGroteskWide;
      }
    }
  }

  .stream_group {
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;

    svg {
      width: 22px;
      cursor: pointer;
    }
  }
}

.next_btn {
  z-index: 3;
}

.prev_btn {
  z-index: 3;
  transform: rotate(180deg);
}

.music_name {
  font-size: 2.5rem;
  margin-top: 1rem;
}

.music_button {
  border: none;
  outline: none;
  background: transparent;
  font-size: 1.6rem;
  border: 1px solid #ffffff;
  padding: 2px 10px;
  cursor: pointer;
  margin-top: 1rem;
  transform: translateY(-20px);
  opacity: 0;
  // transition: 0.2s;

  
}

.videos {
  width: 1600px;
  max-width: 100%;
  // padding: 0 20px;
  // background-color: pink;
  margin: auto;
  // min-height: 100vh;
  //  background-color: #f0f0f0;
  text-align: center;
  display: flex;
  flex-direction: column;
  // margin-bottom: 10rem;
  margin-top: 100px;

  @media (max-width: 769px) {
    margin-top: 0;
  }

  // @media (max-width: 565px) {
  //   margin-bottom: 5rem;
  // }

  img {
    width: 350px;
    height: auto;
  }
}

.videos_title {
  font-size: 4rem;
  text-align: center;
  width: fit-content;
  margin: auto;
  margin-bottom: 4rem;
  margin-top: 0;
  // margin-bottom: 4rem;
  // animation: fontChnage 2s  4s infinite forwards;
}

.videos_inner {
  display: flex;
  align-items: center;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 85%;
  cursor: pointer;

  &::-webkit-scrollbar {
    display: none;
  }
}

.youtube_video_container {
  position: relative;
  height: 400px;
  width: 700px;

  @media (max-width: 768px) {
    .youtube_video_container {
      width: 100%;
      min-width: 100%;
    }
  }
}

.youtube_video {
  width: 700px;
  height: 100%;
}

.video_slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // background-color: yellow;
  margin: auto;

  svg {
    width: 5rem;
  }
}

.svg_phase2 {
  // animation: arrowChange 2s infinite forwards;
  display: none;
}

.shows {
  width: 1200px;
  max-width: 100%;
  padding: 0 20px;
  // background-color: yellow;
  margin: auto;
  // min-height: 100vh;
  /* background-color: #f0f0f0; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // margin-bottom: 10rem;
  margin-top: 100px;

  img {
    width: 30rem;
    height: auto;
  }

  .shows_title {
    font-size: 4rem;
    text-align: center;
    width: fit-content;
    margin: auto;
    margin-bottom: 4rem;
    margin-top: 0;
  }

  .shows_inner {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    overflow-x: auto;
    scroll-behavior: smooth;
    /* flex: 2; */
    /* background-color: yellow; */
    width: 85%;
    cursor: pointer;

    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      position: relative;
      margin-right: 40px;

      .shows_gif {
        height: 300px;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.5s;
        overflow-y: hidden;
      }

      .shows_name {
        font-size: 2.5rem;
        margin-top: 1rem;
      }

      .shows_button {
        border: none;
        outline: none;
        background: transparent;
        font-size: 2.5rem;
        border: 1px solid #ffffff;
        padding: 2px 10px;
        cursor: pointer;
        margin-top: 1rem;
        // transform: translateY(-20px);
        // opacity: 0;
        // transition: 0.2s;

        &:hover {
          font-family: Plact;
        }
      }

      .sold_out {
        opacity: 0.5;
      }
    }

    & > div:hover {
      // .shows_gif {
      //   opacity: 1;

      // }

      .shows_name {
        font-family: Plact;
      }

      .shows_button {
        font-family: Plact;
      }
    }
  }

  .shows_slider {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 5rem;
    }
  }

  .shows_name {
    font-size: 2.5rem;
    margin-top: 1rem;
  }

  .shows_button {
    border: none;
    outline: none;
    background: transparent;
    font-size: 2.5rem;
    border: 1px solid #ffffff;
    padding: 2px 10px;
    cursor: pointer;
    margin-top: 1rem;
    // transform: translateY(-20px);
    // opacity: 0;
    // transition: 0.2s;

    &:hover {
      font-family: Plact;
    }
  }

  .sold_out {
    opacity: 0.5;
  }
}

.shows2 {
  // width: 1200px;
  max-width: 100%;
  // padding: 0 20px;
  // background-color: yellow;
  margin: auto;
  min-height: 100vh;
  /* background-color: #f0f0f0; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10rem;
  position: relative;

  &__card1 {
    display: flex;
    align-items: center;
    gap: 40px;
    width: 100%;
    height: 100vh;
    // background: blue;
    overflow-x: auto;
    // transform: rotate(-10deg);
    padding: 0 20px;

    &::-webkit-scrollbar {
      display: none;
    }

    &__card {
      width: 320px;
      // height: 100%;
      background: #fff;

      padding: 10px 0 30px 0;
      border-radius: 12px;
      font-size: 6rem;
      font-family: "Inter", sans-serif;
      color: #1a1a1a;
      font-weight: 700;
      white-space: wrap;
      text-align: start;
      align-items: center;
      line-height: 80%;
      // word-break: break-all;
      // display: flex;
      // align-items: center;
      // justify-content: center;

      span {
        // background: yellow;
      }

      .amanda-slant {
        width: 100px;
        height: fit-content;
        transform: rotate(270deg);
        // background: yellow;
        margin-top: -30px;
        margin-bottom: -30px;
        margin-right: 20px;
        margin-left: 20px;
      }

      .amanda-straight {
        width: 50px;
        height: auto;
        margin-bottom: -10px;
        // margin-right: 10px;
      }
    }
  }
}

.performanceL {
  width: 6rem !important;
  height: auto;
  margin: auto;
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.performance {
  width: 6rem !important;
  height: auto;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.album {
  width: 1200px;
  max-width: 100%;
  padding: 0 20px;
  // background-color: yellow;
  margin: auto;
  margin-top: 20px;
  /* background-color: #f0f0f0; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 565px) {
    margin-top: 0;
  }

  .album_title {
    font-size: 3.2rem;
    text-align: center;
    width: fit-content;
    margin: auto;
    margin-bottom: 2rem;
    margin-top: 0;
    font-family: RocGroteskWide;

    @media (max-width: 565px) {
      font-size: 20px;
    }

    & > span {
      color: #c3fee0;
    }
  }
  .album_subtitle {
    font-size: 3rem;
    text-align: center;
    width: fit-content;
    margin: auto;
    margin-bottom: 4rem;
    margin-top: 0;
    animation: fontChange 2s infinite forwards;
  }

  .album_button {
    font-size: 12px;
    text-align: center;
    width: fit-content;
    margin: auto;
    // margin-bottom: 4rem;
    margin-top: 0;
    border: 1px solid #fff;
    padding: 5px 18px;
    cursor: pointer;
    font-family: RocGroteskWide;
    text-decoration: none;
  }

  .view {
    width: 100%;
    max-width: 900px;
    margin: auto;
    height: 600px;
    border: 14px solid #fff;
    border-radius: 40px;
    // border-radius
    // padding: 1px;
    text-align: start;
    overflow: hidden;
  }
  .view-img {
    width: 100%;
    max-width: 900px;
    margin: auto;
    height: auto;
    border: 14px solid #fff;
    border-radius: 40px;
    // border-radius
    // padding: 1px;
    text-align: start;
    overflow: hidden;

    & > img {
      width: 100%;
      height: auto;
    }
  }
}

.map {
  width: 100%;
  max-width: 460px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: yellow;
  margin: auto;
  // margin-top: -350px;

  & > svg {
    width: 100%;
    height: 100%;
    // background: blue;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1024px) {
    margin-top: 20px;
  }

  &__active {
    position: relative;
    cursor: pointer;
    &:hover {
      fill: #bbb;
    }
  }
}

.press {
  width: 1200px;
  max-width: 100%;
  margin: auto;
  // height: 100vh;
  // background-color: #f0f0f0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10rem;

  // img {
  //   width: 350px;
  //   height: auto;
  // }
}

// .larger_img {
//   flex-grow: 2;

// }

.press_title {
  font-size: 20rem;
  text-align: center;
  width: fit-content;
  margin: auto;
  margin-bottom: 4rem;
  margin-top: 0;
  animation: fontChange 2s infinite forwards;
}

.press_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  // flex-wrap: wrap;
  // background-color: #f0f0f0;
  // flex-wrap: wrap;

  .inner_max_max {
    flex: 2.5;
    position: relative;
    cursor: pointer;

    &:hover {
      .curtain {
        clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
      }
    }
  }

  .inner_max {
    flex: 2;
    position: relative;
    cursor: pointer;

    &:hover {
      .curtain {
        clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
      }
    }
  }

  .inner_min_max {
    flex: 1.5;
    position: relative;
    cursor: pointer;

    &:hover {
      .curtain {
        clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
      }
    }
  }

  .inner_min {
    flex: 1;
    position: relative;
    cursor: pointer;

    &:hover {
      .curtain {
        clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
      }
    }
  }

  img {
    width: 90%;
    height: auto;
  }

  .amanda_and_i {
    transform: translateX(-250px);
    opacity: 0;
  }

  .searching {
    transform: translateX(250px);
    opacity: 0;
  }

  .standing_ovation {
    transform: translateX(-220px);
    opacity: 0;
    z-index: -1;
  }

  .pose {
    transform: translateX(220px);
    opacity: 0;
    z-index: -1;
  }

  // .hair_cut, .conductor, .topoftheworld, .fly, .vexed, .trans{
  //   transform: translateY(-100px);
  // }

  .second_phase {
    transform: translateY(-100px);
    opacity: 0;
  }

  .second_phase_down {
    transform: translateY(100px);
    opacity: 0;
  }

  .trans {
    transform: rotate(-30deg) translateX(-100px);
    opacity: 0;
  }

  .trans_fly {
    transform: rotate(30deg) translateX(100px);
    opacity: 0;
  }

  .curtain {
    position: absolute;
    background-color: rgba(9, 9, 9, 0.448);
    width: 90%;
    height: 100%;
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 2rem;
    padding: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    svg {
      width: 25px;
      height: auto;

      &:hover {
        stroke: #000;
        fill: #000;
      }
    }

    a {
      text-decoration: underline;

      &:hover {
        font-family: Plact;
      }
    }
  }
}

.gallery {
  &__title {
    font-size: 80px;
    color: #FFF;
    margin: 20px 0;
    // display: none;
    font-family: RocGroteskWide;
    text-transform: uppercase;
    text-align: center;
    line-height: 100%;
    // background: yellow;
  }

  &__grid-1 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    height: 200px;

    & > img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      cursor: pointer;
    }
  }
  &__grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr;
    height: 200px;

    & > img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      cursor: pointer;
    }
  }
  &__grid-3 {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    height: 200px;

    & > img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      cursor: pointer;
    }
  }

  &__modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background: #00000048;
   
      & > img {
        width: 600px;
        height: auto;
        max-height: 70%;
        object-fit: cover;

        @media (max-width: 565px) {
          max-height: 60%;
        }
      }

      & > button {
        border: none;
  outline: none;
  background: transparent;
  font-size: 1.6rem;
  border: 1px solid #ffffff;
  padding: 2px 10px;
  background: #000;
  cursor: pointer;
      }
    
  }
}

.subscribe-modal {
  width: 100%;
  height: 400px;
  background: #3e3e3e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  max-width: 800px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 48px;
  z-index: 2;

  &__title {
    font-size: 6rem;
    color: #fff;
    text-align: center;
    margin-bottom: 2.4rem;
  }

  &__input {
    width: 100%;
    height: 40px;
    color: #000;
    font-size: 1.8rem;
    border: none;
    outline: none;
    border-radius: 7px;
    margin-bottom: 2rem;
    font-weight: 700;
    padding-left: 10px;
  }

  &__button {
    height: 40px;
    width: 60%;
    background: transparent;
    border: none;
    font-size: 3rem;
    cursor: pointer;
    border: 1px solid #ffffff;
    margin: auto;
    margin-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 400px;

    &:hover {
      font-family: Plact;
    }
  }
}

.subscribe-modal-cover {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
.cover-art-modal-cover {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
.cover-art-modal {
  width: 100%;
  height: 400px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  max-width: 800px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 48px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 3;

  img {
    width: 400px;
    height: auto;
  }

  &__title {
    font-size: 4.8rem;
  }
}
footer {
  min-height: 100vh;
  width: 1200px;
  max-width: 100%;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  // background-color: #ddd;
  // display: none;

  .title {
    font-size: 8vw;
    text-align: center;
    margin-bottom: 5rem;
    animation: fontChange 2s infinite forwards;
    // background-color: yellow;
  }

  .form {
    width: 50%;
    // background-color: yellow;
    display: flex;
    flex-direction: column;
    // margin: auto;
    // margin-top: 0 ;
    // margin-top: 0 ;

    label {
      font-size: 2rem;
      // color: #000;
      margin-bottom: 0.5rem;
    }

    input,
    select {
      height: 40px;
      color: #000;
      font-size: 1.8rem;
      border: none;
      outline: none;
      border-radius: 7px;
      margin-bottom: 2rem;
      font-weight: 700;
      padding-left: 10px;
    }

    button {
      height: 40px;
      width: 60%;
      background: transparent;
      border: none;
      font-size: 3rem;
      cursor: pointer;
      border: 1px solid #ffffff;
      margin: auto;
      margin-top: 4rem;
      // width: 400px;

      &:hover {
        font-family: Plact;
      }
    }
  }
}

.none {
  opacity: 0;
  z-index: -1 !important;
  transition: 0.6s;
  margin-left: -50px;
  width: fit-content;
}

.amanda_container {
  width: fit-content;
  height: fit-content;
  // background-color: blue;
  padding: 10px;
  position: fixed;
  right: -200px;
  top: 180px;
  transform: scaleX(-1) rotate(30deg);
  cursor: pointer;
  // display: none;
  z-index: 2;

  &:hover {
    right: -190px !important;
    transition: 1s;
  }

  .amanda {
    width: 200px;
    height: auto;

    // background-color: yellow;
  }

  .amanda_message {
    background-color: #f0f0f0;
    width: 200px;
    min-height: 100px;
    position: fixed;
    top: 2px;
    right: -140px;
    border-radius: 8px;
    opacity: 0;

    & > .amanda_thank_you_text {
      font-size: 2rem;
      font-weight: 700;
      color: #000;
      padding: 10px;
      transform: scaleX(-1);
    }

    .tip {
      width: 60px;
      height: 15px !important;
      background-color: #f0f0f0;
      position: fixed;
      top: 87px;
      right: 15px;
      // transform: rotate(20deg);
      border-radius: 0 0 0 130px;
      z-index: -1;
    }
  }
}

.amanda_message_active {
  opacity: 1 !important;
  transition: 1s;
}




@media (max-width: 768px) {
  header {
    .hamburger {
      display: initial;
      // background-color: yellow;
      padding-left: 20px;
      z-index: 10;

      .hamburger1 {
        width: 25px;
        height: 2px;
        background-color: #ffffff;
        transition: 0.6s;
      }
      .hamburger2 {
        width: 20px;
        height: 2px;
        background-color: #ffffff;
        margin: 5px 0;
        transition: 0.6s;
      }
      .hamburger3 {
        width: 10px;
        height: 2px;
        background-color: #ffffff;
        transition: 0.6s;
      }
    }

    .hamburger_active {
      // position: fixed;
      // top: 50px;
      // left: 20px;

      .hamburger1 {
        width: 35px;
        height: 2px;
        transition: 0.6s;
        transform: rotate(45deg);
      }
      .hamburger2 {
        // display: none !important;
        background: transparent;
        transition: 0.6s;
      }
      .hamburger3 {
        width: 35px;
        transition: 0.6s;
        transform: rotate(-45deg);
        margin-top: -15px;
      }
    }

    .header_title {
      font-size: 6rem;
      // background-color: teal;
      margin-left: -40px;
    }

    .nav {
      display: none;
    }
  }



  .sidebar {
    width: 100%;
    height: 100%;
    // transition: ;
    background-color: #000;
    // display: initial;
    font-size: 3.2rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    font-family: RocGroteskWide;
    text-transform: uppercase;

    // @media (max-width: 565px) {
    //   padding: 60px 0;
    // }


    @media (max-width: 565px) {
      font-size: 18px;
    }
    .hamburger {
      display: initial;
      // background-color: yellow;
      padding-left: 40px;
      z-index: 10;

      .hamburger1 {
        width: 25px;
        height: 2px;
        background-color: #ffffff;
        transition: 0.6s;
      }
      .hamburger2 {
        width: 20px;
        height: 2px;
        background-color: #ffffff;
        margin: 5px 0;
        transition: 0.6s;
      }
      .hamburger3 {
        width: 10px;
        height: 2px;
        background-color: #ffffff;
        transition: 0.6s;
      }
    }

    .hamburger_active {
      .hamburger1 {
        width: 35px;
        height: 2px;
        transition: 0.6s;
        transform: rotate(45deg);
        margin-top: 9.8px;
      }
      .hamburger2 {
        // display: none !important;
        background: transparent;
        transition: 0.6s;
      }
      .hamburger3 {
        width: 35px;
        transition: 0.6s;
        transform: rotate(-45deg);
        margin-top: -16px;
      }
    }

    .sidebar_inner {
      width: 100%;
      // height: 500px;
      // background-color: blue;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 40px;

      & > div {
        margin-bottom: 32px;
      }
    }
  }

  .press_title {
    font-size: 22vw;
  }


  .youtube_video {
    width: 85vw;
  }
}

@media (max-width: 565px) {
  .amanda_container {
    right: -100px;
    .amanda {
      width: 100px;
    }

    .amanda_message {
      width: 150px;
      min-height: 54px;
      right: -126px;
      .tip {
        width: 30px;
        height: 8px !important;
        top: 48px;
        right: 4px;
        // background-color: yellow;
      }
      & > .amanda_thank_you_text {
        font-size: 1.2rem;
        padding: 4px;
      }
    }
  }

  header {
    .header_title {
      font-size: 6rem;
      // background-color: teal;
    }

    .helicopter {
      font-size: 2rem;
    }

  
    
  }

  footer {
    .form {
      width: 100%;
    }
  }



  .store {
    padding: 0;
    img {
      width: 28rem;
    }
  }
  .shows {
    padding: 0;
    img {
      width: 28rem;
    }
  }


  .youtube_video {
    width: 80vw;
    // height: auto;
  }

  .press_inner {
    //  flex-direction: column;
  }
}

@keyframes fontChange {
  0% {
    font-family: Plact;
  }

  100% {
    font-family: Plunct;
  }
}

@keyframes arrowChange {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes draw {
  0% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 18900;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes drawamanda {
  0% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 2500;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

// Locomotive

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100vh;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}
.c-scrollbar:hover {
  transform: scaleX(1.45);
}
.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
  display: none;
}
.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

// ImageGrid.scss
.grid-container {
  width: 100%;
  padding: 1rem;

  .image-grid {
    display: grid;
    gap: 0.5rem;
    height: 500px;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: calc(500px / 6); // Base row height
    grid-auto-flow: dense;
  }

  .grid-item {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease-in-out;
    }

    .overlay {
      position: absolute;
      inset: 0;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 0.3s ease-in-out;
    }

    &:hover {
      z-index: 1;

      img {
        transform: scale(1.1);
      }

      .overlay {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

// ModelViewer.scss
.model-viewer {
  width: 100%;
  height: 400px; // 384px, equivalent to h-96
  // background: yellow;
  canvas {
    width: 100% !important;
    height: 100% !important;
    // background-color: yellow;
  }

  // Optional: Add responsive breakpoints
  @media (min-width: 768px) {
    height: 32rem; // Taller on larger screens
  }

  // Optional: Add loading state styles
}

.model-viewer-desktop {

  // Optional: Add responsive breakpoints
  @media (max-width: 768px) {
    display: none;
  }
}
.model-viewer-mobile {
  display: none;

  // Optional: Add responsive breakpoints
  @media (max-width: 768px) {
    display: initial;
  }
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}


.socials {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 50px;
  max-width: 300px;
  border-radius: 8px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  // background: yellow;

  & > svg {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    cursor: pointer;
  }

  & >:last-child {
    width: 30px;
    height: 30px;
  }
}


// LoadingSpinner.scss
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .spinner {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top-color: #FFF;
    animation: spin 1s ease-in-out infinite;
    
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
  
  .loading-text {
    margin-top: 1rem;
    font-size: 1.125rem;
    font-weight: 500;
    color: #2d3748;
  }
}