.not_found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;

    // background-color: yellow;

    .amanda {
        width: 300px;
        height: auto;
        // background-color: blue;
    }
    .four_o_four {
        font-size: 7rem;
        margin-bottom: 2rem;
        font-weight: 700;
    }
    .title {
        font-size: 7rem;
        margin-bottom: 2rem;
        font-weight: 700;
    }
    .subtitle {
        font-size: 3rem;
        font-weight: 700;
    }

    button {
        background: transparent;
        border: 1px solid #fff;
        outline: none;
        font-size: 2rem;
        padding: 5px 25px;
        border-radius: 18px;
        margin-top: 20px;
        cursor: pointer;

        &:hover {
            font-family: Plact;
          }
    }
}

@media (max-width: 565px) {
    .not_found {
        .amanda {
            width: 150px;
        }

        .four_o_four {
            font-size: 7rem;
        }
        .title {
            font-size: 4rem;
        }
        .subtitle {
            font-size: 2rem;
        }
    }
}